import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-domopique',
  templateUrl: './domopique.component.html',
  styleUrls: ['./domopique.component.scss']
})
export class DomopiqueComponent implements OnInit {

  url: string = '/assets/videos/domopique.mp4';
  type: string = 'video/mp4';

  constructor(
      private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

}
