import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AppComponent} from "./app.component";
import {DomopiqueComponent} from "./domopique/domopique.component";

const routes: Routes = [
  {path: '', component: AppComponent},
  {path: '608538e3b3098608538e3b309a608538e3b309c608538e3b309d', component: DomopiqueComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
